<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'products_list_page'"
          :AllResponse="'allproducts_list'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Produits'"
          :sheetname="'Produits'"
          :showedit="false"
          :key="klist"
          :Add="false"
          :del="false"
          :search_elm="filtre"
          :list_options="true"
          :Kind="parseInt(prd_type)"
          :isExpire="true"
          :exp1_label="'Perissable'"
          :exp2_label="'Périmés/Risque péremption'"
        >
        </listitemspage>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PRODUCTS_LIST_PAGE from "../graphql/Product/PRODUCTS_LIST_PAGE.gql";
import ALLPRODUCTS_LIST from "../graphql/Product/ALLPRODUCTS_LIST.gql";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    prd_type: String,
  },
  data: () => ({
    filtre: "",
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editer: false,
    valider: false,
    klist: 100,
    search: "",
    drawer: null,
    selitem: {},
    headers: [
      {
        text: "N°",
        value: "no",
        selected: true,
      },
      {
        text: "Index",
        value: "code",
        enum: "CODE",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        enum: "REF",
        hiden: true,
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        enum: "LABEL",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "fournis_name",
        enum: "FOURNIS_NAME",
        selected: true,
      },

      {
        text: "Quantité",
        value: "qte_stock",
        slot: "cur",
        enum: "QTE_STOCK",
        align: "end",
        selected: true,
      },
      {
        text: "Unité",
        value: "unit",
        enum: "UNIT",
        selected: true,
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        slot: "date",
        enum: "EXPIRE_DATE",
        selected: true,
      },
      {
        text: "Nb Jour",
        value: "nbjour",
        enum: "NBJOUR",
        selected: true,
      },

      {
        text: "Depot",
        value: "depot_name",
        enum: "DEPOT_NAME",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: true,
        expire: true,
      },
    ],
    changed: false,
    loading: false,
    attributes: [],
    shifts: [],
    arrets: [],
    dechets: [],
    machines: [],
  }),

  computed: {
    Qselect() {
      return PRODUCTS_LIST_PAGE;
    },
    Qselectall() {
      return ALLPRODUCTS_LIST;
    },
  },
  watch: {
    prd_type: {
      handler() {
        this.klist++;
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.headers[2].hiden = this.$store.state.options[0].code_list == "0";
    this.headers[4].hiden = this.prd_type == "34";
  },

  methods: {
    async get_data() {
      this.headers[2].hiden = this.transf;
      this.headers[3].hiden = this.transf;
      this.headers[4].hiden = this.transf;
      this.headers[5].hiden = this.transf;
    },
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
  },
};
</script>
